import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { Banner, mapToPageHeader } from "@components/Banner"
import { SEO, mapToSEO } from "@components/SEO"
import {
  SignupBannerWrapper,
  mapToKiwiVIPVisible,
} from "@components/SignupBanner"
import { mapToFaqContainer, FaqList } from "@components/FaqList"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { GiftVoucher } from "@components/GiftVoucher/index"
import theme from "@styles/theme"

const FAQs = (props: any) => {
  const { elements } = props.data.kontentItemGiftVoucherLandingPage

  // const seo = mapToSEO(elements)
  // const kiwiVip = mapToKiwiVIPVisible(elements)
  // const metadata = mapToPageHeader(elements)
  // const faqs = mapToFaqContainer(elements.faqs.linked_items[0].elements)

  return (
    <Layout location={props.location}>
      {/* <SEO {...seo} />
      <Banner {...metadata} isBiggerBanner={false} />
      <GenericBannerContainer
        padding={{
          mobile: {
            top: 60,
            bottom: 80,
          },
          desktop: {
            top: 80,
            bottom: 100,
          },
        }}
        backgroundColor={theme.brand.colors.beige}
      >
        <GiftVoucher
          Heading={elements.heading.value}
          Summary={elements.summary.value}
          RoboVoucherId="NZM8QD"
        ></GiftVoucher>
      </GenericBannerContainer>
      <GenericBannerContainer padding={{mobile: {top: 50,bottom: 60},desktop: {top: 90,bottom: 100}}} backgroundColor={faqs.backgroundColor}>
      <FaqList
        {...faqs}
        answerColor={theme.brand.colors.white}
        questionColor={theme.brand.colors.white}
        borderColor={theme.brand.colors.white}
      ></FaqList>
      </GenericBannerContainer>
      {kiwiVip.visible && <SignupBannerWrapper version={kiwiVip.version} />} */}
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        apiUrl
      }
    }
    kontentItemGiftVoucherLandingPage {
      elements {
        faqs {
          linked_items {
            ... on KontentItemFaqBlock {
              elements {
                background_colors {
                  value {
                    codename
                    name
                  }
                }
                description{
                  value
                }
                faqs {
                  linked_items {
                    ... on KontentItemFaqItem {
                      elements {
                        answer {
                          value
                        }
                        faq_category {
                          value {
                            codename
                            name
                          }
                        }
                        question {
                          type
                          value
                        }
                      }
                    }
                  }
                }
                title {
                  value
                }
              }
            }
          }
        }
        general_page_content__header_image {
          value {
            url
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            codename
            name
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            codename
            name
          }
        }
        general_page_content__title {
          value
        }
        general_page_content__title_position {
          value {
            codename
            name
          }
        }
        heading {
          value
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        summary {
          value
        }
        url {
          value
        }
      }
    }
  }
`

export default FAQs
